<template>

    <div ref="scene">
        <slot />
    </div>

</template>

<script>
import Parallax from 'parallax-js'

export default {
    name: 'StageParallax',

    mounted() {
        var scene = this.$refs['scene'];
        var parallaxInstance = new Parallax(scene, {
            relativeInput: false
        });
    },
}
</script>

<template>
    <div class="slideshow" ref="slideshow">

        <swiper
            :class="{
                'slideshow--has-navigation': hasNavigation
            }"
            :modules="swiperModules"
            :autoHeight="true"
            :navigation="{
                nextEl: '.slideshow__navigation-button-next',
                prevEl: '.slideshow__navigation-button-prev',
            }"
            :parallax="true"
            @after-init="handleButtonPositions"
            @slide-change="handleButtonPositions"
            @resize="handleButtonPositions"
        >


            <div v-show="showButtons" class="slideshow__navigation-buttons">
                <slot name="navigation-buttons" />
            </div>

            <slot />

        </swiper>

    </div>
</template>

<script>
import { Navigation, Parallax } from 'swiper';

export default {

    name: 'Slideshow',

    props: {

    },

    data: () => {
        return {
            swiperModules: [
                Navigation,
                Parallax
            ],

        };
    },

    computed: {
        hasNavigation() {
            return this.$slots['navigation-buttons']
                && this.$slots.default()
                && this.$slots.default().length > 1
        },

        showButtons() {

            return this.$slots.default() && this.$slots.default().length > 1;
        },
    },

    methods: {


        handleButtonPositions(swiper) {

            if (!(swiper && swiper.navigation)) {
                return;
            }

            // const nextButton = swiper.navigation.nextEl
            // const prevButton = swiper.navigation.prevEl

            const activeIndex = swiper.activeIndex;
            const activeSlide =  swiper.slides[activeIndex];

            const mediaElement = activeSlide.querySelector('.slideshow__image');

            if (!mediaElement) {
                return;
            }

            const mediaBounding = mediaElement.getBoundingClientRect();
            const mediaHeight = mediaBounding.height;
            const mediaRight = mediaBounding.left + mediaBounding.width;

            // nextButton.style.top = `${mediaHeight}px`;
            // prevButton.style.top = `${mediaHeight}px`;

            const slideshowSection = this.$refs['slideshow'].closest('section')

            slideshowSection.style.setProperty('--media-height', `${mediaHeight}px`);
            slideshowSection.style.setProperty('--media-right', `${mediaRight}px`);

            // slideshowSection.style.setProperty('--decoration', `${mediaHeight}px`);

        }
    },
}
</script>

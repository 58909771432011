<template>

    <div :class="['dropdown', dropdownClasses]" ref="dropdown" v-click-outside="close">

        <div class="dropdown__header" @click="toggle">
            <span v-if="showPlaceholder" class="dropdown__item dropdown__placeholder">
                <slot name="placeholder" :placeholder="placeholder">
                    {{ placeholder }}
                </slot>
            </span>

            <span v-if="selectedItem" class="dropdown__item dropdown__item--active">
                <slot name="active-item" :item="selectedItem">
                    {{ selectedItem }}
                </slot>
            </span>

            <span class="dropdown__icon">
                <slot name="icon"></slot>
            </span>
        </div>

        <div class="dropdown__content">

            <div class="dropdown__items" ref="item-list">

                <template v-for="(item, index) in items" :key="index">
                    <span v-if="index != selectedIndex" class="dropdown__item" @click="selectItem(index)">
                        <slot name="item" :index="index" :item="item">
                            {{ item }}
                        </slot>
                    </span>
                </template>

            </div>

        </div>
    </div>

</template>

<script>
import vClickOutside from 'click-outside-vue3';


export default {

    name: 'Dropdown',

    directives: {
      clickOutside: vClickOutside.directive
    },

    props: {
        placeholder: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: () => ([])
        },
        selectedItemIndex: {
            type: Number,
            default: null
        }
    },

    data() {
        return {
            selectedIndex: null,
            isOpen: false
        }
    },

    created() {
        this.selectedIndex = this.selectedItemIndex ?? this.selectedIndex;
    },

    watch: {
        isOpen(newStateOpen) {
            if (newStateOpen) {
                this.expandDropdown();
            } else {
                this.collapseDropdown();
            }
        }
    },

    computed: {

        dropdownClasses() {
            return {
                'dropdown--is-open': this.isOpen
            };
        },

        itemList() {
            return this.items.filter((item, index) => index != this.selectedIndex);
        },

        selectedItem() {
            return this.items[this.selectedIndex] ?? null
        },

        showPlaceholder() {
            return !this.selectedItem && this.placeholder;
        },
    },

    methods: {

        open() {
            this.isOpen = true;
        },

        close() {
            this.isOpen = false;
        },

        toggle() {
            this.isOpen ? this.close() : this.open();
        },

        selectItem(index) {

            this.selectedIndex = index;
            this.close();
        },

        afterExpansion() {

            const itemList = this.$refs['item-list'];

            itemList.style.height = 'auto';
            itemList.removeEventListener('transitionend', this.afterExpansion);
        },

        expandDropdown() {

            const itemList = this.$refs['item-list'];
            const targetHeight = itemList.scrollHeight;

            itemList.style.height = `${targetHeight}px`;

            this.$refs['dropdown'].style.setProperty('--content-height', `${targetHeight}px`);

            itemList.addEventListener('transitionend', this.afterExpansion);
        },

        collapseDropdown() {

            const itemList = this.$refs['item-list'];
            const currentHeight = itemList.scrollHeight;
            const elementTransition = itemList.style.transition;

            itemList.style.transition = '';
            itemList.removeEventListener('transitionend', this.afterExpansion);

            requestAnimationFrame(() => {

                itemList.style.height = `${currentHeight}px`;
                itemList.style.transition = elementTransition;

                requestAnimationFrame(() => {
                    itemList.style.height = 0;
                    this.$refs['dropdown'].style.setProperty('--content-height', null);
                });
            });

        }

    }
}
</script>


const HTML = document.querySelector('html');


export function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

export function disableScrolling() {

    const scrollbarWidth = getScrollbarWidth();

    if (scrollbarWidth > 0) {
        HTML.classList.add('no-scroll--scrollbar-visible');
    } else {
        HTML.classList.add('no-scroll--scrollbar-hidden');
    }

    HTML.style.marginRight = `${scrollbarWidth}px`;
    HTML.style.setProperty('--scroll-bar-width', `${scrollbarWidth}px`);
    HTML.classList.add('no-scroll');
}

export function enableScrolling() {

    HTML.style.marginRight = null;
    HTML.style.setProperty('--scroll-bar-width', null);
    HTML.classList.remove('no-scroll');
    HTML.classList.remove('no-scroll--scrollbar-visible');
    HTML.classList.remove('no-scroll--scrollbar-hidden');

}

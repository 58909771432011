<template>

    <div v-if="appearance == 'list'" class="language-switcher language-switcher--list">

        <a v-for="item in languageItems"
            :class="[
                'language-switcher__item',
                {
                    'language-switcher__item--active': item.current
                }
            ]"
            :href="item.url"
            :hreflang="item.language"
            :lang="item.language"
        >
            {{ item.language }}
        </a>

    </div>

    <dropdown v-else-if="appearance == 'dropdown'" class="language-switcher" :items="languageItems" :selected-item-index="currentLanguage">

        <template #icon><slot name="icon" /></template>

        <template v-slot:active-item="{ item }">
            <span class="language-switcher__item">{{ item.language }}</span>
        </template>

        <template v-slot:item="{ item }">
            <a
                class="language-switcher__item"
                :href="item.url"
                :hreflang="item.language"
                :lang="item.language"
                target="_self"
            >
                {{ item.language }}
            </a>
        </template>

    </dropdown>

</template>

<script>

export default {

    name: 'LanguageSwitcher',

    props: {
        languageItems: {
            type: Array,
            required: true
        },
        appearance: {
            type: String,
            default: 'dropdown'
        }
    },

    computed: {
        currentLanguage() {
            return this.languageItems.findIndex(item => item.current);
        }
    },

}
</script>
